/**
 * We need this adapter because the old test suite is written in mocha
 * using proxyquire which makes is very difficult to extract functions out
 * of the file without the tests breaking or requiring heavy maintenance.
 *
 * Using an adapter we can do two things.
 *
 * 1. we can preserve the legacy code
 * 2. test our new jest test suites against
 *    this code to ensure the functionality is preserved between the
 *    legacy code and the refactored code.
 */
const defineSlotsAdapter =
  ({ config, googleTag, setSlotReference, isDOMLoaded, displayBanners, checkConsentedStatusSourcepoint }) =>
  (pageAdSlotsData, pageLevel) =>
    new Promise<void>((resolve) => {
      // eslint-disable-next-line complexity
      googleTag.cmd.push(() => {
        if (pageLevel) {
          /**
           * @required -This function is used to check if we should use personalised google ads or not, for TCF v2.
           * https://github.es.ecg.tools/ecg-marktplaats/gdpr-consent-string/blob/2f6f601ef77f03dd312e7219c0ac4beac3c9384c/src/helpers/isGooglePurposeAccepted.ts#L5
           *
           * For Soourcepoint, getConsentState(document.cookie).Google from sourcepoint-lib
           */
          const consentStatus = checkConsentedStatusSourcepoint();
          if (consentStatus === 0) {
            googleTag.pubads().setRequestNonPersonalizedAds(1);
          }
        }

        pageAdSlotsData.forEach((data) => {
          const slot = googleTag
            /**
             * Constructs an ad slot with a given ad unit path and size
             * and associates it with the ID of a div element on the page
             * that will contain the ad.
             *
             * @documentation https://developers.google.com/publisher-tag/reference?hl=en#googletag.slot-googletag.defineslotadunitpath,-size,-opt_div
             */
            .defineSlot(data.adUnit, data.fallbackSize, data.target)
            /**
             * Sets an array of mappings from a minimum viewport size to slot size for this slot.
             *
             * @documentation https://developers.google.com/publisher-tag/reference?hl=en#googletag.slot-definesizemappingsizemapping
             */
            .defineSizeMapping(data.mapping)
            /**
             * Adds a service to this slot.
             *
             * @documentation https://developers.google.com/publisher-tag/reference?hl=en#googletag.slot-addserviceservice
             */
            .addService(googleTag.pubads());

          /**
           * This check is needed in case the slot returns null
           * More info here -> https://developers.google.com/publisher-tag/reference?hl=en#googletag.defineSlot
           */
          if (slot) {
            Object.keys(data.bannerParameters).forEach((param) => {
              slot.setTargeting(param, data.bannerParameters[param]);
            });
          }

          setSlotReference(data.target, slot, data.adUnit);
        });

        if (pageLevel) {
          if (config.enableSingleRequest) {
            googleTag.pubads().enableSingleRequest();
          }

          if (config.publisherProvidedId) {
            /**
             * @required - We need to do this
             */
            googleTag.pubads().setPublisherProvidedId(config.publisherProvidedId);
          }

          /**
           * @required - We need to do this
           */
          googleTag.pubads().collapseEmptyDivs();
        }

        googleTag.enableServices();

        /**
         * @required - We need to wait on isDOMLoaded event since display of the ad cannot happen before the content is loaded.
         * https://developers.google.com/publisher-tag/reference#googletag.display
         */

        isDOMLoaded.then(() => {
          displayBanners();
          resolve();
        });
      });
    });

export default defineSlotsAdapter;
